<template>

  <div>

    <!-- TODO: プラン新規登録時に指定したプランタイプで表示制御する -->
    <!-- #region 人数売り -->
    <template>
      <!-- planType==人数売り -->
      <CButton
        color="info"
        class="mb-3"
        @click="addNewPersonModal = true"
      ><i class="cil-plus"></i>{{$t('common.add')}}</CButton>

      <table class="price-table">
        <thead>
          <tr>
            <th>{{$t('planPriceEditPage.personBand')}}</th>
            <th
              v-for="type in priceTypes"
              :key="type.id"
              v-bind:style="{ background: type.color}"
            >{{type.name}}</th>
            <th class="bg-danger">{{$t('common.delete')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="person in roomData"
            :key="person.id"
          >
            <td>{{ person.minPerson | personBand(person.maxPerson) }}</td>
            <td
              v-for="type in priceTypes"
              :key="type.id"
            >
              <!-- {{type.name + person.minPerson + person.maxPerson}} -->
              <CInput
                type="number"
                append="円"
              />
            </td>
            <td>
              <CButton
                color="danger"
                size="sm"
                @click="deletePersonWarningModal =true"
              >{{$t('common.delete')}}</CButton>
              <CModal
                color="danger"
                :closeOnBackdrop="false"
                centered
                :show.sync="deletePersonWarningModal"
              >
                <div class="add-modal">
                  Do you want to delete this person?
                </div>
                <template #header>{{$t('commonMessage.deleteTitle')}}</template>
                <template #footer>
                  <CButton
                    @click="deletePersonWarningModal=false"
                    color="secondary"
                  >
                    <i class="icon cil-x"></i> {{$t('common.close')}}
                  </CButton>
                  <CButton
                    @click="deletePerson(person)"
                    color="success"
                  >
                    <i class="icon cil-save mr-1"></i> {{$t('common.save')}}
                  </CButton>
                </template>
              </CModal>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <!-- #endregion 人数売り -->

    <p>↑人数売り時のみ表示</p>
    <p>↓部屋売り時のみ表示</p>

    <!-- #region 部屋売り -->
    <template>
      <!-- planType==部屋売り -->
      <table class="price-table">
        <thead>
          <tr>
            <th></th>
            <th
              v-for="type in priceTypes"
              :key="type.id"
              v-bind:style="{ background: type.color}"
            >{{type.name}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>部屋料金</td>
            <td
              v-for="type in priceTypes"
              :key="type.id"
            >
              <!-- {{type.name + person.minPerson + person.maxPerson}} -->
              <CInput
                type="number"
                append="円"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <!-- #endregion 部屋売り -->

    <!-- #region 更新ボタン -->
    <CButton
      class="px-5 mt-4"
      color="success"
      @click="onUpdateClicked()"
      v-bind:class="activeStatus"
    >
      <i class="cil-task"></i> {{ $t("common.update") }}
    </CButton>
    <!-- #endregion 更新ボタン -->

    <!-- #region Modal -->
    <CModal
      color="info"
      size="lg"
      :closeOnBackdrop="false"
      centered
      :show.sync="addNewPersonModal"
    >
      <div class="add-modal">
        <CForm class="submit_form">
          <CRow>
            <CCol class="label"><strong class="required-mark">Min Person</strong></CCol>
            <CCol>
              <CInput v-model="newRoomData.minPerson" />
            </CCol>
          </CRow>
          <CRow>
            <CCol class="label"><strong class="required-mark">Max Person</strong></CCol>
            <CCol>
              <CInput v-model="newRoomData.maxPerson" />
            </CCol>
          </CRow>
        </CForm>

      </div>
      <template #header>Add New Person</template>
      <template #footer>
        <CButton
          @click="addNewPersonModal=false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}
        </CButton>
        <CButton
          @click="addNewPersonType()"
          color="success"
        >
          <i class="icon cil-save mr-1"></i> {{$t('common.save')}}
        </CButton>
      </template>
    </CModal>
    <!-- #endregion Modal -->

  </div>
</template>

<script>
// import axios from "axios";

export default {
  // filters: {
  //   personBand(min, max) {
  //     if(min === max) return `${min}名`;
  //     return `${min}名～${max}名`;
  //   },
  // },

  data() {
    return {
      addNewPersonModal: false,
      deletePersonWarningModal: false,
      personPriceD: [],
      roomData: [
        {
          id: 1,
          minPerson: 1,
          maxPerson: 1,
        },
        {
          id: 2,
          minPerson: 2,
          maxPerson: 2,
        },
        {
          id: 3,
          minPerson: 3,
          maxPerson: 5,
        },
      ],
      newRoomData: {
        minPerson: 0,
        maxPerson: 0,
      },
      priceTypes: [
        {
          id: 1,
          name: "Spring",
          label: "Spring",
          color: "#f9b115",
          colorIndex: "A",
        },
        {
          id: 2,
          name: "Summer",
          label: "Summer",
          color: "#dd7e6b",
          colorIndex: "B",
        },
        {
          id: 3,
          name: "Winter",
          label: "Winter",
          color: "#39f",
          colorIndex: "W",
        },
      ],
    };
  },

  methods: {
    addNewPersonType() {
      this.roomData.push(this.newRoomData);
      this.addNewPersonModal = false;
    },
    deletePerson(person) {
      this.roomData.splice(
        this.roomData.findIndex((pm) => pm.id === person.id),
        1
      );
      this.deletePersonWarningModal = false;
    },
  },

  beforeMount() { },
};
</script>

<style lang="scss" >
.price-table {
  width: 100%;
  border-collapse: collapse;
  tr {
    th,
    td {
      border: 1px solid #c8c8cc;
      padding: 0 1rem;
    }
  }
  thead {
    tr {
      th {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        &:first-child {
          background: #e7e6e6;
          width: 200px;
        }
      }
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background: #ecf7f8;
      }
      td {
        &:first-child {
          background: #e7e6e6;
        }
      }
    }
  }
}
</style>
