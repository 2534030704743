<template>
  <!-- 
    画面: プラン料金編集  削除タブ
    用途: プランｘ部屋タイプｘ掲載サイトを削除する
   -->
  <div>

    <!-- #region Action field -->
    <template>
      TODO: 初期化ボタンにする<br>
      <!-- #region 削除ボタン -->
      <CButton
        class="px-5 mt-4"
        color="danger"
        @click="onDeleteClicked()"
      >
        <i class="cil-trash"></i> {{ $t("common.delete") }}
      </CButton>
      <!-- #endregion 削除ボタン -->
    </template>
    <!-- #endregion Action field -->

    <!-- #region Modal -->
    <template>

      <DeleteConfirmModal
        :deleteConfirmModal="deleteConfirmModal"
        :callback="reqDelete"
        @close="deleteConfirmModal = $event"
      />

      <SuccessModal
        :successModal="successModal"
        @close="successModal = $event"
      />

      <ErrorModal
        :errorModal="errorModal"
        :errorMessage="errorMessage"
        @close="errorModal = $event"
      />

    </template>
    <!-- #endregion Modal -->

  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "PlanPriceEditDelete",

  components: {
  },

  props: {},

  data() {
    return {

      //#region Flag

      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,

      //#endregion Flag


      //#region Response data

      response: [],

      //#endregion Response data

      errorMessage: "",

    }
  },

  computed: {},

  methods: {

    //#region Event

    /** 削除ボタン押下 */
    onDeleteClicked() {
      this.deleteConfirmModal = true;
    },

    /** 削除ボタン押下 */
    onDeleteSubmit() {
      this.delete();
    },

    //#endregion Event


    //#region Request

    /** プランｘ部屋タイプｘ掲載サイト削除削除 */
    reqDelete() {
      // TODO: API開発後に結合
      this.deleteConfirmModal = false;
      this.successModal = true;
    },

    //#endregion Request

  },

  beforeMount() {
  },

};
</script>
