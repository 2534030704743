<template>
  <!-- 
    画面: プランx部屋タイプ 編集
    用途: プランx部屋タイプ を更新または削除する
   -->
  <div>
    <!-- #region Main -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol md="3">
                <strong class="card-title mr-3">{{ $t("planPriceEditPage.pageTitle") }}</strong>
              </CCol>
              <CCol>
                <!-- TODO: デザイン修正 -->
                <span class="float-right">
                  <CAlert
                    color="info"
                    class="float-left py-0 px-3  my-0 mx-1"
                  ><strong>
                      {{ $t("planPriceEditPage.planName") }}</strong>: {{ header.planName }}</CAlert>
                  <CAlert
                    color="info"
                    class="float-left py-0 px-3 my-0 mx-1"
                  ><strong>{{ $t("planPriceEditPage.planTypeName") }}</strong>: {{ header.roomTypeName }}</CAlert>
                  <CAlert
                    color="info"
                    class="float-left py-0  my-0 mx-1"
                  ><strong>{{ $t("planPriceEditPage.planSiteName") }}</strong>: {{ header.siteName }}</CAlert>
                </span>
              </CCol>
            </CRow>
          </CCardHeader>

          <CCardBody>

            <CForm class="submit_form">

              <CTabs
                class="edit_tab"
                fade
                :active-tab="$store.state.planRoomTypeEditActiveTab"
              >

                <!-- #region 日別料金 -->
                <CTab
                  :title="$t('planPriceEditPage.tab0')"
                  @click.native="activeTab(0)"
                >
                  <PlanPriceEditDailyPrice />
                </CTab>
                <!-- #endregion 日別料金 -->

                <!-- #region 基準料金 -->
                <CTab
                  :title="$t('planPriceEditPage.tab1')"
                  @click.native="activeTab(1)"
                >
                  <PlanPriceEditBasic />
                </CTab>
                <!-- #endregion 基準料金 -->

                <!-- #region 年齢区分料金 -->
                <CTab
                  :title="$t('planPriceEditPage.tab2')"
                  @click.native="activeTab(2)"
                >
                  <PlanPriceEditAge />
                </CTab>
                <!-- #endregion 年齢区分料金 -->

                <!-- #region 自動延長 -->
                <CTab
                  :title="$t('planPriceEditPage.tab3')"
                  @click.native="activeTab(3)"
                >
                  <PlanPriceEditExtension />
                </CTab>
                <!-- #endregion 自動延長 -->

                <!-- #region 直前割 -->
                <CTab
                  :title="$t('planPriceEditPage.tab4')"
                  @click.native="activeTab(4)"
                >
                  <PlanPriceEditDiscount />
                </CTab>
                <!-- #endregion 直前割 -->

                <!-- #region ポイント設定 -->
                <CTab
                  :title="$t('planPriceEditPage.tab5')"
                  @click.native="activeTab(5)"
                >
                  <PlanPriceEditPoint />
                </CTab>
                <!-- #endregion ポイント設定 -->

                <!-- #region 初期化 -->
                <CTab
                  :title="$t('planPriceEditPage.tab6')"
                  @click.native="activeTab(6)"
                >
                  <PlanPriceEditDelete />
                </CTab>
                <!-- #endregion 初期化 -->

              </CTabs>

            </CForm>

          </CCardBody>

          <CCardFooter>

            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
            <!-- #endregion 戻るボタン -->

          </CCardFooter>

        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Main -->

  </div>
</template>

<script>
// import axios from "axios";
import PlanPriceEditDailyPrice from "./components/EditDailyPrice";
import PlanPriceEditBasic from "./components/EditBasic";
import PlanPriceEditAge from "./components/EditAge";
import PlanPriceEditExtension from "./components/EditExtension";
import PlanPriceEditDiscount from "./components/EditDiscount";
import PlanPriceEditPoint from "./components/EditPoint";
import PlanPriceEditDelete from "./components/EditDelete";

export default {
  name: "RoomTypeEdit",

  components: {
    PlanPriceEditDailyPrice,
    PlanPriceEditBasic,
    PlanPriceEditAge,
    PlanPriceEditExtension,
    PlanPriceEditDiscount,
    PlanPriceEditPoint,
    PlanPriceEditDelete,
  },

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },
  },

  data() {
    return {
      //#region Flag

      loading: false,
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,

      //#endregion Flag

      //#region Input

      header: {
        planName: "プラン名A",
        roomTypeName: "部屋タイプ名A",
        siteName: "掲載サイト名A",
      },

      edit: {
        code: "",
        name: "",
        capacityMin: 1,
        capacityMax: 3,
        baseInventory: 1,
        description: "",
      },

      //#endregion Input

      //#region Response data

      response: [],

      //#endregion Response data
    };
  },

  methods: {
    //#region Event

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    //#endregion Event

    //#region Request
    //#endregion Request

    //#region Method

    /**
     * save tab no for page reload
     */
    activeTab(TabNo) {
      // this.$store.commit("set", ["planRoomTypeEditActiveTab", TabNo]);
      // NOTE: ここはタブ記憶不要。store保持しない。
      TabNo;
    },

    backPage() {
      this.$router.go(-1);
    },

    //#endregion Method
  },
};
</script>
