<template>
  <div class="calender-price-list">
    <CRow class="mb-2">

      <!-- #region 前の4カ月 -->
      <CCol>
        <CButton
          class="btn-light"
          @click="onPrev4monthsClicked()"
        >
          <i class="icon cil-chevron-double-left"></i> {{$t('planPriceEditPage.prev4months')}}
        </CButton>
      </CCol>
      <!-- #endregion 前の4カ月 -->

      <!-- #region 当月 -->
      <CCol>
        <CButton
          class="btn-light"
          @click="onCurrentMonthClicked()"
        >
          {{$t('planPriceEditPage.currentMonth')}}
        </CButton>
      </CCol>
      <!-- #endregion 当月 -->

      <!-- #region 次の4カ月 -->
      <CCol>
        <CButton
          class="btn-light"
          @click="onNext4monthsClicked()"
        >
          {{$t('planPriceEditPage.next4months')}} <i class="icon cil-chevron-double-right"></i>
        </CButton>
      </CCol>
      <!-- #endregion 次の4カ月 -->

      <!-- #region 基準料金設定 -->
      <!-- <CCol>
        <CButton
          class="btn-light"
          @click="onBaseChargeApplicationClicked()"
        >
          <i class="cil-sync"></i> {{$t('planPriceEditPage.baseChargeApplication')}}
        </CButton>
      </CCol> -->
      <!-- #endregion 基準料金設定 -->

      <!-- #region 一括料金設定 -->
      <CCol>
        <CButton
          class="btn-light"
          @click="onBulkChargeSettingClicked()"
        >
          <i class="cil-settings"></i> {{$t('planPriceEditPage.bulkChargeSetting')}}
        </CButton>
      </CCol>
      <!-- #endregion 一括料金設定 -->

      <!-- #region 一括直前割設定 -->
      <CCol>
        <CButton
          class="btn-light"
          @click="onBulkDiscountSettingClicked()"
        >
          <i class="cil-settings"></i> {{$t('planPriceEditPage.bulkDiscountSetting')}}
        </CButton>
      </CCol>
      <!-- #endregion 一括直前割設定 -->
    </CRow>

    <!-- TODO: コンポーネント化したい -->
    <Calendar
      class="custom-calendar max-w-full"
      :locale="$i18n.locale"
      :attributes="attributes"
      disable-page-swipe
      :is-expanded="$screens({ default: true, lg: false })"
      show-weeknumbers
      :columns="$screens({ default: 1, lg: 1, xl:2 })"
      :rows="$screens({ default: 1, lg: 4, xl:2, md:4 })"
    >

      <template v-slot:day-content="{ day }">
        <div
          v-if="day.weekday == 1"
          class="price-type-cell"
        >
          <ul>
            <li><span>1-1名</span></li>
            <li><span>2-2名</span></li>
            <li><span>3-3名</span></li>
            <li><span>4-4名</span></li>
          </ul>
        </div>
        <div
          class="flex flex-col h-full z-10 overflow-hidden h-100"
          :class="getDayAttrClass(day)"
          @click="calendarDay(day)"
        >
          <!-- {{dayEvents}}
          <p @click="demo(attributesMap)">click</p> -->
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div class="flex-grow overflow-y-auto overflow-x-auto">
            <template v-for="attr in day.attributes">
              <div :key="attr.customData.title">
                <p
                  v-if="attr.customData.bg.label"
                  class="text-xs leading-tight rounded-sm px-1 mt-0 mb-1"
                  v-bind:style="{ background: attr.customData.bg.color }"
                >
                  {{ attr.customData.bg.label }}
                </p>
              </div>
            </template>
            <ul>
              <li>
                <CButton
                  size="sm"
                  block
                  class="p-0"
                  color="info"
                >未設定</CButton>
              </li>
              <li
                v-for="n in 4"
                :key="n"
              >
                <CInput
                  type="number"
                  class="input-c"
                />
              </li>
            </ul>
          </div>
        </div>
      </template>
    </Calendar>

    <!-- <pre><code>{{singleDate}}</code></pre> -->
    
    <!-- #region 更新ボタン -->
    <CButton
      class="px-5 mt-4"
      color="success"
      @click="onUpdateClicked()"
      v-bind:class="activeStatus"
    >
      <i class="cil-task"></i> {{ $t("common.update") }}
    </CButton>
    <!-- #endregion 更新ボタン -->

    <!-- #region 一括料金設定 -->
    <!-- TODO: コンポーネント化する -->
    <CModal
      size="lg"
      color="info"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="bulkChargeSettingModal"
    >
      <template #header> {{ $t("planPriceEditPage.bulkChargeSetting") }} </template>

      {{ $t("planPriceEditPage.bulkChargeSettingCaption") }}

      <!-- #region モード -->
      <CRow>
        <CCol
          class="label"
          md="2"
        ><strong>モード</strong></CCol>
        <CCol>
          <CInputRadioGroup
            :options="['基準料金','円','円引','円増']"
            :checked.sync="mode"
            inline
          />
        </CCol>
      </CRow>
      <!-- #endregion モード -->

      <!-- #region 設定期間 -->
      <CRow class="mb-2">
        <CCol
          class="label"
          md="2"
        ><strong>設定期間</strong></CCol>
        <CCol md="3">
          <DatePicker
            v-model="startDate"
            :min-date="today()"
            :popover="{ visibility: 'click' }"
            @dayclick="onStartDateDayClicked()"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="border px-2 py-1 rounded"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </CCol>
        ～
        <CCol md="3">
          <DatePicker
            v-model="endDate"
            :max-date="dayBeforeOneYearLater()"
            :popover="{ visibility: 'click' }"
            @change="onEndDateDayClicked()"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="border px-2 py-1 rounded"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </CCol>
      </CRow>
      <!-- #endregion 設定期間 -->

      <!-- #region 料金 -->
      <CRow class="mb-4">
        <CCol md="10">
          <CDataTable
            hover
            border
            size="sm"
            :fields="tableFields"
            :items="tableItems"
          >
            <!-- #region ID -->
            <td
              slot="id"
              slot-scope="{ item }"
              class="text-left"
            >
              {{ item.id }}
            </td>
            <!-- #endregion ID -->

            <!-- #region 人数帯 -->
            <td
              slot="personBand"
              slot-scope="{ item }"
              class="text-left"
            >
              {{ item.min  | personBand(item.max) }}
            </td>
            <!-- #endregion 人数帯 -->

            <!-- #region 料金 -->
            <td
              slot="price"
              slot-scope="{ item }"
              class="text-left"
            >
              <CInput
                v-model.number="item.price"
                type="number"
                append="円"
              />
            </td>
            <!-- #endregion 料金 -->
          </CDataTable>
        </CCol>
      </CRow>
      <!-- #endregion 料金 -->

      <template #footer>
        <CButton
          @click="onBulkChargeSettingCloseClicked()"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.cancel')}}
        </CButton>
        <CButton
          @click="onBulkChargeSettingApplicationClicked()"
          color="info"
        >
          <i class="icon cil-sync"></i> {{$t('common.run')}}
        </CButton>
      </template>
    </CModal>
    <!-- #endregion 一括料金設定 -->

    <!-- #region 一括直前割設定 -->
    <!-- TODO: コンポーネント化する -->
    <CModal
      size="lg"
      color="info"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="bulkDiscountSettingModal"
    >
      <template #header> {{ $t("planPriceEditPage.bulkDiscountSetting") }} </template>

      {{ $t("planPriceEditPage.bulkDiscountSettingCaption") }}

      <!-- #region 設定期間 -->
      <CRow class="mb-2">
        <CCol
          class="label"
          md="2"
        ><strong>設定期間</strong></CCol>
        <CCol md="3">
          <DatePicker
            v-model="startDate"
            :min-date="today()"
            :popover="{ visibility: 'click' }"
            @dayclick="onStartDateDayClicked()"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="border px-2 py-1 rounded"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </CCol>
        ～
        <CCol md="3">
          <DatePicker
            v-model="endDate"
            :max-date="dayBeforeOneYearLater()"
            :popover="{ visibility: 'click' }"
            @change="onEndDateDayClicked()"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="border px-2 py-1 rounded"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </CCol>
      </CRow>
      <!-- #endregion 設定期間 -->

      <template #footer>
        <CButton
          @click="onBulkDiscountSettingCloseClicked()"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.cancel')}}
        </CButton>
        <CButton
          @click="onBulkDiscountSettingApplicationClicked()"
          color="info"
        >
          <i class="icon cil-sync"></i> {{$t('common.run')}}
        </CButton>
      </template>
    </CModal>
    <!-- #endregion 一括直前割設定 -->
  </div>

</template>

<script>
// import axios from "axios";

export default {
  data() {
    return {
      //#region Flag
      bulkChargeSettingModal: false,
      bulkDiscountSettingModal: false,
      //#endregion Flag

      addTypeModal: false,
      editTypeModal: false,

      days: [],
      singleDate: "",
      SelectedPriceType: {},
      attributes: [],
      test: "",

      edit: {},

      //#region 一括料金設定モーダル用
      mode: "基準料金",
      startDate: this.today(),
      endDate: this.dayBeforeOneYearLater(),

      tableItems: [
        {
          id: 1,
          min: 1,
          max: 1,
          price: 1000,
        },
        {
          id: 2,
          min: 2,
          max: 3,
          price: 1100,
        },
      ],

      tableFields: [
        {
          key: "id",
          label: this.$t("planPriceEditPage.id"),
          _classes: "text-center bg-light",
        },
        {
          key: "personBand",
          label: this.$t("planPriceEditPage.personBand"),
          _classes: "text-center bg-light",
        },
        {
          key: "price",
          label: this.$t("planPriceEditPage.price"),
          _classes: "text-center bg-light",
          _style: "width:30%",
        },
      ],
      //#endregion 一括料金設定モーダル用

      reload: 1,
    };
  },

  beforeMount() {
    this.getCatendarData();
  },

  methods: {

    //#region  Event
    onPrev4monthsClicked() {

    },

    onCurrentMonthClicked() {

    },

    onNext4monthsClicked() {

    },

    onBaseChargeApplicationClicked() {

    },

    onBulkChargeSettingClicked() {
      this.bulkChargeSettingModal = true;
    },

    /** 一括料金設定Modal 閉じるボタン押下 */
    onBulkChargeSettingCloseClicked() {
      this.bulkChargeSettingModal = false;
    },

    /** 一括料金設定Modal 実行ボタン押下 */
    onBulkChargeSettingApplicationClicked() {
      this.bulkChargeSettingModal = false;
    },

    onBulkDiscountSettingClicked() {
      this.bulkDiscountSettingModal = true;
    },

    onBulkDiscountSettingCloseClicked() {
      this.bulkDiscountSettingModal = false;
    },

    onBulkDiscountSettingApplicationClicked() {
      this.bulkDiscountSettingModal = false;
    },
    //#endregion Event

    /** get all date data of calendar */
    getCatendarData() {
      const month = new Date().getMonth();
      const year = new Date().getFullYear();
      var sample = [
        { date: 2, title: "200", class: "bg-danger", price: 5000 },
        { date: 10, title: "400", class: "bg-success", price: 7000 },
        { date: 21, title: "800", class: "bg-info", price: 9000 },
      ];
      sample.forEach((e, i) => {
        this.attributes.push({
          key: i,
          customData: {
            title: e.title,
            class: e.class,
            price: e.price,
            bg: "",
          },
          dates: new Date(year, month, e.date),
        });
      });
    },

    /**
     *  style class: get class name from day attributes
     */
    getDayAttrClass(dd) {
      return dd.attributesMap && Object.keys(dd.attributesMap).length > 0
        ? Object.values(dd.attributesMap)[0].customData.bg
        : "";
    },
    /** get a custom data from a date attributes */
    getDayAttr(a) {
      return a.attributesMap
        ? a.attributesMap[Object.keys(a.attributesMap)[0]].customData
        : "";
    },
    /** click: set a attributes of a date data */
    calendarDay(day) {
      if (this.SelectedPriceType.isSelect) {
        if (day.attributes && day.attributes.length > 0) {
          var dd = this.getDayAttr(day);
          dd.bg = this.SelectedPriceType;
        } else {
          this.attributes.push({
            customData: {
              bg: this.SelectedPriceType,
            },
            dates: day.date,
          });
        }
      }
      this.singleDate = day;
    },


    // TODO: あとで共通処理に
    /** 本日 */
    today() {
      return new Date();
    },

    // TODO: あとで共通処理に
    /** 1年後の前日 */
    dayBeforeOneYearLater() {
      let date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      date.setDate(date.getDate() - 1);
      return date;
    },
  },
};
</script>


<style lang="scss">
.calender-price-list {
  .vc-pane {
    margin: 0.5rem;
    border: 1px solid #cccccc;
  }
  .price-type-cell {
    position: absolute;
    left: -50px;
    ul {
      margin-top: 47px;
      li {
        clear: both;
        padding: 0;
        margin: 2px 4px;
        display: block;
        height: 24px;
        // border: 1px solid #cccccc;
      }
    }
  }
  .vc-weeknumber {
    width: 50px;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    .vc-weeknumber-content {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }
  .custom-calendar.vc-container {
    --day-border: 1px solid #b8c2cc;
    --day-border-highlight: 1px solid #b8c2cc;
    // --day-width: 90px;
    // --day-height: 90px;
    --weekday-bg: #f8fafc;
    --weekday-border: 1px solid #eaeaea;
    border-radius: 0;
    width: 100%;
    & .vc-header {
      background-color: #f1f5f8;
      padding: 10px 0;
    }
    & .vc-weeks {
      padding: 0;
    }
    & .vc-weekday {
      background-color: var(--weekday-bg);
      border-bottom: var(--weekday-border);
      border-top: var(--weekday-border);
      padding: 5px 0;
    }
    & .vc-day {
      padding: 0 5px 3px 5px;
      text-align: left;
      height: var(--day-height);
      min-width: var(--day-width);
      background-color: white;
      &.weekday-1,
      &.weekday-7 {
        background-color: #eff8ff;
      }
      &:not(.on-bottom) {
        border-bottom: var(--day-border);
        &.weekday-1 {
          border-bottom: var(--day-border-highlight);
        }
      }
      &:not(.on-right) {
        border-right: var(--day-border);
      }
    }
    & .vc-day-dots {
      margin-bottom: 5px;
    }
    .input-c {
      input {
        padding: 0 3px;
        height: 1.5rem;
        background: #d2fde5;
        border: 1px solid #70bb92;
        text-align: right;
        min-width: 54px;
      }
    }
  }

  // color picker start
  .color-picker-table {
    td,
    th {
      vertical-align: middle;
    }
  }

  .color-code {
    padding: 10px 0px 0px 10px;
    float: left;
  }

  .vue-swatches {
    float: left;
  }

  .overlay-table {
    .vue-swatches {
      float: none;
    }
  }

  .vue-swatches__check__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .vue-swatches__check__circle {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.15);
  }

  .vue-swatches__check__path {
    fill: #fff;
  }

  .vue-swatches__swatch {
    position: relative;
    display: inline-block;
    font-size: 0;
    width: 30px !important;
    height: 30px !important;
    border-radius: 5px !important;
    margin: 0 10px 0 0 !important;
    border: 1px solid #cccccc;
  }

  fieldset[disabled] .vue-swatches {
    pointer-events: none;
  }

  .vue-swatches {
    position: relative;
    display: inline-block;
    outline: none;
  }

  .vue-swatches__trigger {
    width: 30px !important;
    height: 30px !important;
    border: 1px solid #cccccc;
  }
  .select-lbl {
    //select batch
    font-size: 0.8em;
    line-height: 1rem;
    display: inline-block;
    margin-left: 4px;
    border-radius: 5px;
    padding: 0 5px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>