<template>
  <!-- 
    画面: プラン編集  ポイントタブ
    用途: プランのポイントを設定する
   -->
  <div>

    <!-- #region Input field -->
    <template>

      <!-- #region 基本ポイント付与率 -->
      <CRow>
        <CCol class="label"><strong>{{ $t("planEditPage.basicPointGrantRate") }}</strong></CCol>
        <CCol>
          <ul class="ul-line-block">
            <li>
              <CInput
                v-model="edit.basicPointGrantRate"
                type="number"
              />
            </li>
            <li>
              {{ $t("planEditPage.percent") }}
            </li>
          </ul>
        </CCol>
      </CRow>
      <!-- #endregion 基本ポイント付与率 -->

      <!-- #region ポイント有効期間 -->
      <CRow>
        <CCol class="label"><strong>{{ $t("planEditPage.pointEffectivePeriod") }}</strong></CCol>
        <CCol>
          <ul class="ul-line-block">
            <li>
              <CInput
                v-model="edit.pointEffectivePeriod"
                type="number"
              />
            </li>
            <li>
              {{ $t("planEditPage.day") }}
            </li>
          </ul>
        </CCol>
      </CRow>
      <!-- #endregion ポイント有効期間 -->

    </template>
    <!-- #endregion Input field -->

    <!-- #region Action field -->
    <template>
      <!-- #region 更新ボタン -->
      <CButton
        class="px-5 mt-4"
        color="info"
        @click="onUpdateClicked()"
        v-bind:class="activeStatus"
      >
        <i class="cil-task"></i> {{ $t("common.update") }}
      </CButton>
      <!-- #endregion 更新ボタン -->
    </template>
    <!-- #endregion Action field -->

    <!-- #region Modal -->
    <template>

      <SuccessModal
        :successModal="successModal"
        @close="successModal = $event"
      />

      <ErrorModal
        :errorModal="errorModal"
        :errorMessage="errorMessage"
        @close="errorModal = $event"
      />

    </template>
    <!-- #endregion Modal -->

  </div>
</template>

<script>
// import axios from "axios";

import {
  required,
  // numeric,
  // minLength,
  // maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "PlanPriceEditPoint",

  components: {},

  props: {},

  data() {
    return {

      //#region Flag

      loading: false,
      successModal: false,
      errorModal: false,

      //#endregion Flag


      //#region Edit

      edit: {
        basicPointGrantRate: 5,
        pointEffectivePeriod: 10,
      },

      //#endregion Edit


      //#region Response data

      response: [],

      //#endregion Response data

      //#region Message

      errorMessage: "",

      //#endregion Message

    }
  },

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? 'inactive' : 'active'
    }
  },

  validations() {
    return {
      edit: {
        code: { required },
        name: { required },
        capacityMin: { required },
        capacityMax: { required },
        baseInventory: { required },
        description: {},
      }
    };
  },

  methods: {

    //#region Event

    /** 更新ボタン押下 */
    onUpdateClicked() {
      this.$v.edit.$invalid ? this.$v.edit.$touch() : this.reqPut();
    },

    //#endregion Event


    //#region Request

    /** プラン更新 */
    reqPut() {
      this.errorMessage = "エラーメッセージテスト";
      this.errorModal = true;
      // this.successModal = true;

      const roomTypeId = this.$route.params.roomTypeId;
      const url = `/rest/roomType/${roomTypeId}`;
      const body = this.edit;
      console.log(url);
      console.log(body);

      // TODO: API開発後に実装する

    },

    //#endregion Request

    //#region Method

    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas.forEach(data => {
        this.tableItems.push(
          {
            id: data.id,
            code: data.code,
            name: data.name,
            capacity: `${data.capacityMin} ~ ${data.capacityMax}`,
            baseInventory: data.baseInventory,
            size: data.size,
            status: data.status,
          }
        );
      });
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },

    //#endregion Method

  },

  beforeMount() {
  },

};
</script>
