<template>
  <!-- 
    画面: プラン料金編集  直前割タブ
    用途: プラン料金の直前割を設定する
   -->
  <div>

    <!-- #endregion 直前割種別 -->
    <CRow class="mb-2">
      <CCol class="label">
        <strong class="required-mark">{{ $t("planPriceEditPage.discountType") }}</strong>
      </CCol>
      <CCol>
        <CInputRadioGroup
          :options="discountTypes"
          :checked.sync="edit.discountType"
          inline
        />
        <!-- <CRow>
          {{ $t("planPriceEditPage.discountPercent") }}:
          {{ $t("planPriceEditPage.discountPercentCaption") }}
        </CRow>
        <CRow>
          {{ $t("planPriceEditPage.discountAmount") }}:
          {{ $t("planPriceEditPage.discountAmountCaption") }}
        </CRow>
        <CRow>
          {{ $t("planPriceEditPage.premiumPercent") }}:
          {{ $t("planPriceEditPage.premiumPercentCaption") }}
        </CRow>
        <CRow>
          {{ $t("planPriceEditPage.premiumAmount") }}:
          {{ $t("planPriceEditPage.premiumAmountCaption") }}
        </CRow> -->
      </CCol>
    </CRow>
    <!-- #endregion 直前割種別 -->

    <!-- #region 一覧表 -->
    <CRow>
      <CCol class="label">
        <strong class="required-mark">{{ $t("planPriceEditPage.discountContent") }}</strong>
      </CCol>
      <CCol>
        <table class="price-table-x">
          <thead>
            <tr>
              <!-- TODO: 部屋売り対応 -->
              <th>{{ $t("planPriceEditPage.priceBand") }}</th>
              <th
                v-for="item in daysAgoes"
                :key="item.id"
                v-bind:style="{ background: '' }"
              >
                {{ item.label }}
                <template v-if="isActiveDelete(item.id)">
                  <CButton
                    color="danger"
                    @click="onDeleteClicked()"
                    size="sm"
                    class="mr-1"
                  >-</CButton>
                </template>
                <template v-if="isActiveAdd(item.id)">
                  <CButton
                    color="info"
                    @click="onAddClicked()"
                    size="sm"
                  >+</CButton>
                </template>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in records"
              :key="item.id"
            >
              <!-- TODO: 部屋売り対応 -->
              <td>{{ item.min | personBand(item.max) }}</td>
              <td
                v-for="discount in item.discounts"
                :key="discount.id"
              >
                <CInput
                  type="number"
                  :value.sync="discount.value"
                />
              </td>
            </tr>
          </tbody>
        </table>

      </CCol>
    </CRow>
    <!-- #endregion 一覧表 -->

    <!-- #region 更新ボタン -->
    <CButton
      class="px-5 mt-4"
      color="success"
      @click="onUpdateClicked()"
      v-bind:class="activeStatus"
    >
      <i class="cil-task"></i> {{ $t("common.update") }}
    </CButton>
    <!-- #endregion 更新ボタン -->

    <!-- #region Modal -->
    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
    <!-- #endregion Modal -->

  </div>
</template>

<script>
// import axios from "axios";
// import i18n from "@/i18n";

export default {
  name: "PlanPriceDiscount",

  components: {
  },

  props: {},

  data() {
    return {
      //#region Flag
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,
      //#endregion Flag


      discountTypes: [
        { value: 0, label: this.$t("planPriceEditPage.discountPercent") },
        { value: 1, label: this.$t("planPriceEditPage.discountAmount") },
        { value: 2, label: this.$t("planPriceEditPage.premiumPercent") },
        { value: 3, label: this.$t("planPriceEditPage.premiumAmount") },
      ],

      edit: {
        discountType: 0
      },

      daysAgoes: [
        { id: 0, label: "当日", },
        { id: 1, label: "1日前", },
        { id: 2, label: "2日前", },
        { id: 3, label: "3日前", },
      ],

      records: [
        { id: 0, min: 1, max: 1, discounts: [{ id: 0, value: 100 }, { id: 1, value: 200 }, { id: 2, value: 300 }, { id: 3, value: 400 }] },
        { id: 1, min: 2, max: 2, discounts: [{ id: 0, value: 110 }, { id: 1, value: 210 }, { id: 2, value: 310 }, { id: 3, value: 410 }] },
        { id: 2, min: 3, max: 3, discounts: [{ id: 0, value: 120 }, { id: 1, value: 220 }, { id: 2, value: 320 }, { id: 3, value: 420 }] },
        { id: 3, min: 4, max: 6, discounts: [{ id: 0, value: 130 }, { id: 1, value: 230 }, { id: 2, value: 330 }, { id: 3, value: 430 }] },
      ],

      //#region Response data
      response: [],
      //#endregion Response data

      errorMessage: "",

    }
  },

  computed: {
  },

  methods: {
    //#region Event
    /** 削除ボタン押下 */
    onDeleteClicked() {
      const targetIndex = this.daysAgoes.length - 1;
      this.daysAgoes.splice(targetIndex, 1);
      this.records.forEach(a => a.discounts.splice(targetIndex, 1));
    },

    /** 追加ボタン押下 */
    onAddClicked() {
      const targetIndex = this.daysAgoes.length - 1;
      this.daysAgoes.splice(targetIndex + 1, 0, {
        id: targetIndex + 1,
        label: `${targetIndex + 1}日前`,
      });
      this.records.forEach(a => a.discounts.splice(targetIndex + 1, 0, {
        id: targetIndex + 1,
        value: 0,
      }));
    },

    /** 削除ボタン押下 */
    onDeleteSubmit() {
      this.delete();
    },
    //#endregion Event


    //#region Request
    /** プランｘ部屋タイプｘ掲載サイト削除削除 */
    reqDelete() {
      // TODO: API開発後に結合
      this.deleteConfirmModal = false;
      this.successModal = true;
    },
    //#endregion Request


    //#region Method
    isActiveDelete(value) {
      if (value === 0) return false;
      // console.log(value, this.daysAgoes.length - 1);
      return value == this.daysAgoes.length - 1;
    },

    isActiveAdd(value) {
      // console.log(value, this.daysAgoes.length - 1);
      return value == this.daysAgoes.length - 1;
    },
    //#endregion Method
  },

  beforeMount() {
  },

};
</script>
<style lang="scss" >
.price-table-x {
  // width: 100%;
  border-collapse: collapse;
  tr {
    th,
    td {
      border: 1px solid #c8c8cc;
      padding: 0 1rem;
    }
  }
  thead {
    tr {
      th {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        text-align: center;
      }
      &:first-child {
        background: #e7e6e6;
      }
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background: #ecf7f8;
      }
      td {
        width: 130px;
      }
      td {
        &:first-child {
          background: #e7e6e6;
          width: 120px;
        }
      }
    }
  }
}
</style>

