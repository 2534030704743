<template>
  <!-- 
    画面: プラン料金編集  年齢区分料金タブ
    用途: プランｘ部屋タイプｘ掲載サイトの年齢区分料金を設定する
   -->
  <div>

    <!-- <ol>
      <li>・受入対象とするかを設定します</li>
      <li>・宿泊料金の大人人数カウントに含めるかを設定します</li>
      <li>・大人料金を基準にした料金補正を設定します</li>
    </ol> -->

    <!-- #region Action field -->
    <CDataTable
      border
      hover
      size="sm"
      :fields="tableFields"
      :items="tableItems"
    >

      <!-- #region Table record -->
      <td
        slot="id"
        slot-scope="{ item }"
        class="text-left"
        v-if="item.asunaro !== 1"
      >
        <strong>{{ item.id }}</strong>
      </td>

      <td
        slot="name"
        slot-scope="{ item }"
      >
        <strong>{{ item.name }}</strong>
      </td>

      <td
        slot="isAccept"
        slot-scope="{ item }"
        class="text-center"
      >
        <CSwitch
          class="mt-1 swc sw-2"
          color="info"
          data-on="On"
          data-off="Off"
          :labelOn='$t("common.do")'
          :labelOff='$t("common.doNot")'
          variant="opposite"
          :checked="item.isAccept"
          :disabled="isAdult(item.name)"
        />
      </td>

      <td
        slot="isAdultCount"
        slot-scope="{ item }"
        class="text-center"
      >
        <CSwitch
          class="mt-1 swc sw-2"
          color="info"
          data-on="On"
          data-off="Off"
          :labelOn='$t("common.do")'
          :labelOff='$t("common.doNot")'
          variant="opposite"
          :checked="item.isAdultCount"
          :disabled="isAdult(item.name)"
        />

      </td>

      <td
        slot="priceCorrectionType"
        slot-scope="{ item }"
      >
        <template v-if="isAdult(item.name)">
          日別料金が適用されます
        </template>
        <template v-else>
          <CInputRadioGroup
            :options="priceCorrectionTypes"
            :checked.sync="item.priceCorrectionType"
            inline
          />
        </template>
      </td>

      <td
        slot="priceCorrectionValue"
        slot-scope="{ item }"
      >
        <template v-if="!isAdult(item.name)">
          <CInput
            v-model.number="item.priceCorrectionValue"
            type="number"
          />
        </template>
      </td>
      <!-- #endregion Table record -->

    </CDataTable>
    <!-- #endregion Action field -->

    <!-- #region 更新ボタン -->
    <CButton
      class="px-5 mt-4"
      color="success"
      @click="onUpdateClicked()"
      v-bind:class="activeStatus"
    >
      <i class="cil-task"></i> {{ $t("common.update") }}
    </CButton>
    <!-- #endregion 更新ボタン -->

    <!-- #region Modal -->
    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
    <!-- #endregion Modal -->

  </div>
</template>

<script>
// import axios from "axios";
import i18n from "@/i18n";
import STATUS from "@/mixins/property";

export default {
  name: "PlanPriceEditDelete",

  mixins: [STATUS],

  components: {
  },

  props: {},

  data() {
    return {

      //#region Flag

      successModal: false,
      errorModal: false,

      //#endregion Flag

      tableFields: [
        {
          key: "name",
          label: `${i18n.t("planPriceEditPage.name")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "isAccept",
          label: `${i18n.t("planPriceEditPage.isAccept")}`,
          _classes: "text-center bg-light",
          _style: "width: 8%",
        },
        {
          key: "isAdultCount",
          label: `${i18n.t("planPriceEditPage.isAdultCount")}`,
          _classes: "text-center bg-light",
          _style: "width: 10%",
        },
        {
          key: "priceCorrectionType",
          label: `${i18n.t("planPriceEditPage.priceCorrection")}`,
          _classes: "text-center bg-light",
          _style: "width: 12%",
        },
        {
          key: "priceCorrectionValue",
          label: `${i18n.t("planPriceEditPage.value")}`,
          _classes: "text-center bg-light",
          _style: "width: 20%",
        },
      ],

      tableItems: [],

      //#region Response data
      // response: [],
      response: [
        {
          asunaro: 1,
          name: "大人",
          isAccept: true,
          isAdultCount: true,
          priceCorrectionType: 0,
          priceCorrectionValue: 100,
          status: 100,
        },
        {
          asunaro: 11,
          name: "小学生",
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 1,
          priceCorrectionValue: 60,
          status: 100,
        },
        // {
        //   asunaro: 11,
        //   name: "小学生(高学年)",
        //   isAccept: true,
        //   isAdultCount: false,
        //   priceCorrectionType: 1,
        //   priceCorrectionValue: 60,
        //   status: 100,
        // },
        // {
        //   asunaro: 12,
        //   name: "小学生(低学年)",
        //   isAccept: true,
        //   isAdultCount: false,
        //   priceCorrectionType: 1,
        //   priceCorrectionValue: 40,
        //   status: 100,
        // },
        {
          asunaro: 21,
          name: "幼児(食事有・布団有)",
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 3000,
          status: 100,
        },
        {
          asunaro: 22,
          name: "幼児(食事有・布団無)",
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 2000,
          status: 100,
        },
        {
          asunaro: 23,
          name: "幼児(食事無・布団有)",
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 1000,
          status: 100,
        },
        {
          asunaro: 24,
          name: "幼児(食事無・布団無)",
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 0,
          status: 100,
        },
        {
          asunaro: 31,
          name: "乳児(食事有・布団有)",
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 2000,
          status: 200,
        },
        {
          asunaro: 32,
          name: "乳児(食事有・布団無)",
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 1000,
          status: 200,
        },
        {
          asunaro: 33,
          name: "乳児(食事無・布団有)",
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 1000,
          status: 200,
        },
        {
          asunaro: 34,
          name: "乳児(食事無・布団無)",
          isAccept: true,
          isAdultCount: false,
          priceCorrectionType: 0,
          priceCorrectionValue: 0,
          status: 200,
        },
      ],
      //#endregion Response data

      // TODO: 多言語化
      priceCorrectionTypes: [
        { value: 0, label: "円" },
        { value: 1, label: "％" },
        { value: 2, label: "円引" },
      ],

      errorMessage: "",

    }
  },

  computed: {},

  methods: {

    //#region Event

    //#endregion Event


    //#region Request

    reqGet() {
    },

    reqPut() {
      this.successModal = true;
    },

    //#endregion Request

    //#region Method

    isAdult(name) {
      // TODO: ハードコーディング解消
      return name == '大人';
    },

    /**
 * 一覧表にデータを流し込む
 * @param datas
 */
    pourTable(datas) {
      this.clearTable();
      datas.forEach((data) => {
        if (data.asunaro === 1) return; // 大人を除外
        if (data.status === this.STATUS.INVALID) return; // 無効を除外

        this.tableItems.push({
          asunaro: data.asunaro,
          name: data.name,
          isAccept: data.isAccept,
          isAdultCount: data.isAdultCount,
          chargeCorrectionType: data.chargeCorrectionType,
          chargeCorrectionValue: data.chargeCorrectionValue,
          status: data.status,
        });
      });

      console.log(JSON.stringify(this.tableItems));
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },

    //#endregion Method

  },

  beforeMount() {
    console.log(this.response);
    this.pourTable(this.response);
  },

};
</script>
