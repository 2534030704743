<template>
  <!-- 
    画面: プラン料金編集  自動延長タブ
    用途: プランｘ部屋タイプｘ掲載サイトの自動延長を設定する
   -->
  <div>

    <!-- #region Action field -->
    <template>
      <CRow>
        <!-- <CCol class="label"><strong>{{ $t("planEditPage.option") }}</strong></CCol> -->
        <CCol class="label"><strong>自動延長</strong></CCol>
        <CCol>
          <CRow>
            <CCol md="2">
              自動延長
              <CSwitch
                class="mt-1 swc sw-2"
                color="info"
                variant="opposite"
                :labelOn='$t("common.do")'
                :labelOff='$t("common.doNot")'
                :checked="edit.isAutoExtended"
                @update:checked="onStatusChanged()"
              />
            </CCol>
            <CCol>
              <template v-if="edit.isAutoExtended">
                <CRow>
                  毎月
                  <CSelect
                    :options="days"
                    :checked.sync="edit.day"
                    inline
                  />
                  日に
                  <CSelect
                    :options="monthsTargets"
                    :checked.sync="edit.monthsTarget"
                    inline
                  />
                  カ月先の月末まで自動延長
                </CRow>
                <CRow>
                  次回の自動延長日：2018/01/01に、2018/03月末まで自動延長
                  1～31日（当月最大値を超えた日は月末日扱い）、0～13ヶ月先
                </CRow>
              </template>
            </CCol>
          </CRow>
        </CCol>

      </CRow>
    </template>
    <!-- #endregion Action field -->

    <!-- #region 更新ボタン -->
    <CButton
      class="px-5 mt-4"
      color="success"
      @click="onUpdateClicked()"
      v-bind:class="activeStatus"
    >
      <i class="cil-task"></i> {{ $t("common.update") }}
    </CButton>
    <!-- #endregion 更新ボタン -->

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />

  </div>
</template>

<script>
import i18n from "@/i18n";
// import axios from "axios";

export default {
  name: "PlanPriceEditDelete",

  components: {
  },

  props: {},

  data() {
    return {

      //#region Flag

      successModal: false,
      errorModal: false,

      //#endregion Flag

      settingList: [
        { value: false, label: `${i18n.t("planEditPage.noLimit")}` },
        { value: true, label: `${i18n.t("planEditPage.hasLimit")}` },
      ],

      //#region Edit

      edit: {
        day: "",
        monthsTarget: "",
        isAutoExtended: false,
      },

      days: [
        { value: 1, label: "1" },
        { value: 5, label: "5" },
        { value: 10, label: "10" },
        { value: 15, label: "15" },
        { value: 20, label: "20" },
        { value: 25, label: "25" },
        { value: 30, label: "30" },
      ],

      monthsTargets: [
        { value: 0, label: "0" },
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
      ],

      //#endregion Edit

      //#region Response data

      response: [],

      //#endregion Response data

      errorMessage: "",

    }
  },

  computed: {},

  methods: {

    //#region Event

    /** 有効無効切替 */
    onStatusChanged() {
      this.edit.isAutoExtended = !this.edit.isAutoExtended;
      console.log(this.edit.isAutoExtended);
    },

    //#endregion Event


    //#region Request

    reqGet() {
    },

    reqPut() {
      this.successModal = true;
    },

    //#endregion Request

  },

  beforeMount() {
  },

};
</script>
